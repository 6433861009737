
import slugifyMixin from '@/mixins/slugify'

import BurgerIcon from '@/static/svg/nav/burger-icon.svg'
import LenstoreLogo from '@/static/svg/lenstore-logo.svg'
import CloseIcon from '@/static/svg/nav/close-icon.svg'
import MinusIcon from '@/static/svg/nav/minus-icon.svg'
import PlusIcon from '@/static/svg/nav/plus-icon.svg'
import ChevronIcon from '@/static/svg/chevron-icon.svg'
import HeroButton from '@/components/HeroButton'
import TransformImage from '@/components/TransformImage'

export default {
  components: {
    BurgerIcon,
    LenstoreLogo,
    CloseIcon,
    MinusIcon,
    PlusIcon,
    ChevronIcon,
    HeroButton,
    TransformImage,
  },
  mixins: [slugifyMixin],
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      openedMenu: null,
      animate: true,
    }
  },
  computed: {
    loggedIn() {
      return this.$auth.loggedIn
    },
  },

  methods: {
    toggle() {
      this.animate = true
      this.isOpen = !this.isOpen
      this.openedMenu = null
    },
    openMenu(menuItem) {
      if (menuItem.title === this.openedMenu) {
        this.openedMenu = null
      } else {
        this.$refs.menu.scrollTop = 0
        this.openedMenu = menuItem.title
      }
    },
    goToHelpCenter() {
      const externalHelpCenters = {
        externalLink: 'https://www.lenstore.help/en-gb',
        externalLinkDE: 'https://www.lenstore.help/de-de',
        externalLinkIT: 'https://www.lenstore.help/it-it',
        externalLinkFR: 'https://www.lenstore.help/fr-fr',
        externalLinkES: 'https://www.lenstore.help/es-es',
      }
      window.open(this.$getExternalLink(externalHelpCenters), '_blank')
    },
    goToContactUs() {
      this.$router.push(this.$getLink('/document/2'))
      this.closeMenu()
    },
    openChat() {
      this.$openLiveChat()
    },
    closeMenu() {
      this.animate = false
      this.isOpen = false
    },
    login() {
      this.$router.push(this.$getLink('/login'))
      this.closeMenu()
    },
    async logout() {
      await this.$store.dispatch('logOut')
      this.closeMenu()
    },
  },
}
