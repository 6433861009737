export const AccordionContainer = () => import('../../components/AccordionContainer.vue' /* webpackChunkName: "components/accordion-container" */).then(c => wrapFunctional(c.default || c))
export const AccountLogin = () => import('../../components/AccountLogin.vue' /* webpackChunkName: "components/account-login" */).then(c => wrapFunctional(c.default || c))
export const AccountMenu = () => import('../../components/AccountMenu.vue' /* webpackChunkName: "components/account-menu" */).then(c => wrapFunctional(c.default || c))
export const AddressEditor = () => import('../../components/AddressEditor.vue' /* webpackChunkName: "components/address-editor" */).then(c => wrapFunctional(c.default || c))
export const AddressFinder = () => import('../../components/AddressFinder.vue' /* webpackChunkName: "components/address-finder" */).then(c => wrapFunctional(c.default || c))
export const AddressSelector = () => import('../../components/AddressSelector.vue' /* webpackChunkName: "components/address-selector" */).then(c => wrapFunctional(c.default || c))
export const AnimatedLoginForm = () => import('../../components/AnimatedLoginForm.vue' /* webpackChunkName: "components/animated-login-form" */).then(c => wrapFunctional(c.default || c))
export const ApplePay = () => import('../../components/ApplePay.vue' /* webpackChunkName: "components/apple-pay" */).then(c => wrapFunctional(c.default || c))
export const AuthorBlock = () => import('../../components/AuthorBlock.vue' /* webpackChunkName: "components/author-block" */).then(c => wrapFunctional(c.default || c))
export const AutoReorderInterval = () => import('../../components/AutoReorderInterval.vue' /* webpackChunkName: "components/auto-reorder-interval" */).then(c => wrapFunctional(c.default || c))
export const AutoReorderSetup = () => import('../../components/AutoReorderSetup.vue' /* webpackChunkName: "components/auto-reorder-setup" */).then(c => wrapFunctional(c.default || c))
export const BasketGuesstimation = () => import('../../components/BasketGuesstimation.vue' /* webpackChunkName: "components/basket-guesstimation" */).then(c => wrapFunctional(c.default || c))
export const BasketItem = () => import('../../components/BasketItem.vue' /* webpackChunkName: "components/basket-item" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbsNavigation = () => import('../../components/BreadcrumbsNavigation.vue' /* webpackChunkName: "components/breadcrumbs-navigation" */).then(c => wrapFunctional(c.default || c))
export const BurgerMenu = () => import('../../components/BurgerMenu.vue' /* webpackChunkName: "components/burger-menu" */).then(c => wrapFunctional(c.default || c))
export const CardPayment = () => import('../../components/CardPayment.vue' /* webpackChunkName: "components/card-payment" */).then(c => wrapFunctional(c.default || c))
export const CommunicationPreference = () => import('../../components/CommunicationPreference.vue' /* webpackChunkName: "components/communication-preference" */).then(c => wrapFunctional(c.default || c))
export const ContentTile = () => import('../../components/ContentTile.vue' /* webpackChunkName: "components/content-tile" */).then(c => wrapFunctional(c.default || c))
export const CreateAccount = () => import('../../components/CreateAccount.vue' /* webpackChunkName: "components/create-account" */).then(c => wrapFunctional(c.default || c))
export const CurrencyDropdown = () => import('../../components/CurrencyDropdown.vue' /* webpackChunkName: "components/currency-dropdown" */).then(c => wrapFunctional(c.default || c))
export const DropdownSelector = () => import('../../components/DropdownSelector.vue' /* webpackChunkName: "components/dropdown-selector" */).then(c => wrapFunctional(c.default || c))
export const EmptyBasket = () => import('../../components/EmptyBasket.vue' /* webpackChunkName: "components/empty-basket" */).then(c => wrapFunctional(c.default || c))
export const ExpandTransition = () => import('../../components/ExpandTransition.vue' /* webpackChunkName: "components/expand-transition" */).then(c => wrapFunctional(c.default || c))
export const ExpandTransitionGroup = () => import('../../components/ExpandTransitionGroup.vue' /* webpackChunkName: "components/expand-transition-group" */).then(c => wrapFunctional(c.default || c))
export const ExpressPaymentOptions = () => import('../../components/ExpressPaymentOptions.vue' /* webpackChunkName: "components/express-payment-options" */).then(c => wrapFunctional(c.default || c))
export const FadingTransition = () => import('../../components/FadingTransition.vue' /* webpackChunkName: "components/fading-transition" */).then(c => wrapFunctional(c.default || c))
export const FeatureTile = () => import('../../components/FeatureTile.vue' /* webpackChunkName: "components/feature-tile" */).then(c => wrapFunctional(c.default || c))
export const GlobalFooter = () => import('../../components/GlobalFooter.vue' /* webpackChunkName: "components/global-footer" */).then(c => wrapFunctional(c.default || c))
export const GlobalHeader = () => import('../../components/GlobalHeader.vue' /* webpackChunkName: "components/global-header" */).then(c => wrapFunctional(c.default || c))
export const GooglePay = () => import('../../components/GooglePay.vue' /* webpackChunkName: "components/google-pay" */).then(c => wrapFunctional(c.default || c))
export const GooglePayPurple = () => import('../../components/GooglePayPurple.vue' /* webpackChunkName: "components/google-pay-purple" */).then(c => wrapFunctional(c.default || c))
export const HeroBanner = () => import('../../components/HeroBanner.vue' /* webpackChunkName: "components/hero-banner" */).then(c => wrapFunctional(c.default || c))
export const HeroButton = () => import('../../components/HeroButton.vue' /* webpackChunkName: "components/hero-button" */).then(c => wrapFunctional(c.default || c))
export const HeroTabs = () => import('../../components/HeroTabs.vue' /* webpackChunkName: "components/hero-tabs" */).then(c => wrapFunctional(c.default || c))
export const ImageCarousel = () => import('../../components/ImageCarousel.vue' /* webpackChunkName: "components/image-carousel" */).then(c => wrapFunctional(c.default || c))
export const InfoTooltip = () => import('../../components/InfoTooltip.vue' /* webpackChunkName: "components/info-tooltip" */).then(c => wrapFunctional(c.default || c))
export const InputField = () => import('../../components/InputField.vue' /* webpackChunkName: "components/input-field" */).then(c => wrapFunctional(c.default || c))
export const KlarnaWidget = () => import('../../components/KlarnaWidget.vue' /* webpackChunkName: "components/klarna-widget" */).then(c => wrapFunctional(c.default || c))
export const LiftToLegacyModal = () => import('../../components/LiftToLegacyModal.vue' /* webpackChunkName: "components/lift-to-legacy-modal" */).then(c => wrapFunctional(c.default || c))
export const LineItemPrescription = () => import('../../components/LineItemPrescription.vue' /* webpackChunkName: "components/line-item-prescription" */).then(c => wrapFunctional(c.default || c))
export const LoadingSpinner = () => import('../../components/LoadingSpinner.vue' /* webpackChunkName: "components/loading-spinner" */).then(c => wrapFunctional(c.default || c))
export const LocalPayment = () => import('../../components/LocalPayment.vue' /* webpackChunkName: "components/local-payment" */).then(c => wrapFunctional(c.default || c))
export const LocalPaymentWrapper = () => import('../../components/LocalPaymentWrapper.vue' /* webpackChunkName: "components/local-payment-wrapper" */).then(c => wrapFunctional(c.default || c))
export const LoginForm = () => import('../../components/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const MegaMenu = () => import('../../components/MegaMenu.vue' /* webpackChunkName: "components/mega-menu" */).then(c => wrapFunctional(c.default || c))
export const MenuSpotlight = () => import('../../components/MenuSpotlight.vue' /* webpackChunkName: "components/menu-spotlight" */).then(c => wrapFunctional(c.default || c))
export const Message = () => import('../../components/Message.vue' /* webpackChunkName: "components/message" */).then(c => wrapFunctional(c.default || c))
export const Modal3DS = () => import('../../components/Modal3DS.vue' /* webpackChunkName: "components/modal3-d-s" */).then(c => wrapFunctional(c.default || c))
export const ModalDialog = () => import('../../components/ModalDialog.vue' /* webpackChunkName: "components/modal-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalDialogRecommendations = () => import('../../components/ModalDialogRecommendations.vue' /* webpackChunkName: "components/modal-dialog-recommendations" */).then(c => wrapFunctional(c.default || c))
export const MyAccountLayout = () => import('../../components/MyAccountLayout.vue' /* webpackChunkName: "components/my-account-layout" */).then(c => wrapFunctional(c.default || c))
export const NewPaypalExpress = () => import('../../components/NewPaypalExpress.vue' /* webpackChunkName: "components/new-paypal-express" */).then(c => wrapFunctional(c.default || c))
export const OptionalNuxtLink = () => import('../../components/OptionalNuxtLink.vue' /* webpackChunkName: "components/optional-nuxt-link" */).then(c => wrapFunctional(c.default || c))
export const OrderSummary = () => import('../../components/OrderSummary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c))
export const OverlayContainer = () => import('../../components/OverlayContainer.vue' /* webpackChunkName: "components/overlay-container" */).then(c => wrapFunctional(c.default || c))
export const PaymentButtonWrapper = () => import('../../components/PaymentButtonWrapper.vue' /* webpackChunkName: "components/payment-button-wrapper" */).then(c => wrapFunctional(c.default || c))
export const PaymentInput = () => import('../../components/PaymentInput.vue' /* webpackChunkName: "components/payment-input" */).then(c => wrapFunctional(c.default || c))
export const PaypalAdvance = () => import('../../components/PaypalAdvance.vue' /* webpackChunkName: "components/paypal-advance" */).then(c => wrapFunctional(c.default || c))
export const PaypalExpress = () => import('../../components/PaypalExpress.vue' /* webpackChunkName: "components/paypal-express" */).then(c => wrapFunctional(c.default || c))
export const PaypalExpressLegacy = () => import('../../components/PaypalExpressLegacy.vue' /* webpackChunkName: "components/paypal-express-legacy" */).then(c => wrapFunctional(c.default || c))
export const PaypalExpressVault = () => import('../../components/PaypalExpressVault.vue' /* webpackChunkName: "components/paypal-express-vault" */).then(c => wrapFunctional(c.default || c))
export const ProductGuesstimation = () => import('../../components/ProductGuesstimation.vue' /* webpackChunkName: "components/product-guesstimation" */).then(c => wrapFunctional(c.default || c))
export const ProductImage = () => import('../../components/ProductImage.vue' /* webpackChunkName: "components/product-image" */).then(c => wrapFunctional(c.default || c))
export const ProductListingBanner = () => import('../../components/ProductListingBanner.vue' /* webpackChunkName: "components/product-listing-banner" */).then(c => wrapFunctional(c.default || c))
export const ProductListingLayout = () => import('../../components/ProductListingLayout.vue' /* webpackChunkName: "components/product-listing-layout" */).then(c => wrapFunctional(c.default || c))
export const ProductPrescription = () => import('../../components/ProductPrescription.vue' /* webpackChunkName: "components/product-prescription" */).then(c => wrapFunctional(c.default || c))
export const ProductPrescriptionDropdown = () => import('../../components/ProductPrescriptionDropdown.vue' /* webpackChunkName: "components/product-prescription-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ProductPrescriptionOptionSelector = () => import('../../components/ProductPrescriptionOptionSelector.vue' /* webpackChunkName: "components/product-prescription-option-selector" */).then(c => wrapFunctional(c.default || c))
export const ProductReviews = () => import('../../components/ProductReviews.vue' /* webpackChunkName: "components/product-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductTile = () => import('../../components/ProductTile.vue' /* webpackChunkName: "components/product-tile" */).then(c => wrapFunctional(c.default || c))
export const ProgressBar = () => import('../../components/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c))
export const ProgressSteps = () => import('../../components/ProgressSteps.vue' /* webpackChunkName: "components/progress-steps" */).then(c => wrapFunctional(c.default || c))
export const PromoCode = () => import('../../components/PromoCode.vue' /* webpackChunkName: "components/promo-code" */).then(c => wrapFunctional(c.default || c))
export const QuantitySelector = () => import('../../components/QuantitySelector.vue' /* webpackChunkName: "components/quantity-selector" */).then(c => wrapFunctional(c.default || c))
export const RecommendationCarousel = () => import('../../components/RecommendationCarousel.vue' /* webpackChunkName: "components/recommendation-carousel" */).then(c => wrapFunctional(c.default || c))
export const ReviewStars = () => import('../../components/ReviewStars.vue' /* webpackChunkName: "components/review-stars" */).then(c => wrapFunctional(c.default || c))
export const SearchBar = () => import('../../components/SearchBar.vue' /* webpackChunkName: "components/search-bar" */).then(c => wrapFunctional(c.default || c))
export const ShippingSelector = () => import('../../components/ShippingSelector.vue' /* webpackChunkName: "components/shipping-selector" */).then(c => wrapFunctional(c.default || c))
export const ShippingThreshold = () => import('../../components/ShippingThreshold.vue' /* webpackChunkName: "components/shipping-threshold" */).then(c => wrapFunctional(c.default || c))
export const StockLevel = () => import('../../components/StockLevel.vue' /* webpackChunkName: "components/stock-level" */).then(c => wrapFunctional(c.default || c))
export const StoreLocator = () => import('../../components/StoreLocator.vue' /* webpackChunkName: "components/store-locator" */).then(c => wrapFunctional(c.default || c))
export const StoredCard = () => import('../../components/StoredCard.vue' /* webpackChunkName: "components/stored-card" */).then(c => wrapFunctional(c.default || c))
export const StoredPayment = () => import('../../components/StoredPayment.vue' /* webpackChunkName: "components/stored-payment" */).then(c => wrapFunctional(c.default || c))
export const TransformImage = () => import('../../components/TransformImage.vue' /* webpackChunkName: "components/transform-image" */).then(c => wrapFunctional(c.default || c))
export const UspBanner = () => import('../../components/UspBanner.vue' /* webpackChunkName: "components/usp-banner" */).then(c => wrapFunctional(c.default || c))
export const LayoutsCheckoutLayout = () => import('../../components/layouts/CheckoutLayout.vue' /* webpackChunkName: "components/layouts-checkout-layout" */).then(c => wrapFunctional(c.default || c))
export const LayoutsPricingLayout = () => import('../../components/layouts/PricingLayout.vue' /* webpackChunkName: "components/layouts-pricing-layout" */).then(c => wrapFunctional(c.default || c))
export const LayoutsSmallMessage = () => import('../../components/layouts/SmallMessage.vue' /* webpackChunkName: "components/layouts-small-message" */).then(c => wrapFunctional(c.default || c))
export const StoreLocatorMap = () => import('../../components/storeLocator/LocatorMap.vue' /* webpackChunkName: "components/store-locator-map" */).then(c => wrapFunctional(c.default || c))
export const StoreTile = () => import('../../components/storeLocator/StoreTile.vue' /* webpackChunkName: "components/store-tile" */).then(c => wrapFunctional(c.default || c))
export const StoreLocatorStoresList = () => import('../../components/storeLocator/StoresList.vue' /* webpackChunkName: "components/store-locator-stores-list" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
